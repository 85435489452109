export const PricingData = [
    [
        {title: 'Basic' ,name: 'Web Design', price: 119},
        {title: 'Standard' ,name: 'Web Design', price: 219},
        {title: 'Premium' ,name: 'Web Design', price: 549},
    ],
    [
        {title: 'Basic' ,name: 'Email Marketing', price: 390},
        {title: 'Standard' ,name: 'Email Marketing', price: 790},
        {title: 'Premium' ,name: 'Email Marketing', price: 999},
    ],
    [
        {title: 'Basic' ,name: 'Graphic Design', price: 125},
        {title: 'Standard' ,name: 'Graphic Design', price: 235},
        {title: 'Premium' ,name: 'Graphic Design', price: 564},
    ],
    [
        {title: 'Basic' ,name: 'Web Development', price: 458},
        {title: 'Standard' ,name: 'Web Development', price: 699},
        {title: 'Premium' ,name: 'Web Development', price: 899},
    ],
    [
        {title: 'Basic' ,name: 'SEO', price: 564},
        {title: 'Standard' ,name: 'SEO', price: 799},
        {title: 'Premium' ,name: 'SEO', price: 899},
    ],
    [
        {title: 'Basic' ,name: 'UI Design', price: 499},
        {title: 'Standard' ,name: 'UI Design', price: 799},
        {title: 'Premium' ,name: 'UI Design', price: 999},
    ]
]