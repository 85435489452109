import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faInstagram, faLinkedinIn, faTwitter } from '@fortawesome/free-brands-svg-icons'
import FooterCol from './FooterCol';
import './Footer.css';
import { usefulLink, ourServices, otherLinks, footerInfo } from '../../FooterData';
import FooterInfo from './FooterInfo';
import { Link } from 'react-router-dom';
import { scrollUP } from '../../Shared/ScrollTop/ScrollTop';


const Footer = () => {
    return (
        <section className='row footer'>
            <Row className="col-md-11 mx-auto">
                <Row className="align-items-center footerInfo">
                    {
                        footerInfo.map(data => <FooterInfo data={data} key={data.id}/>)
                    }
                </Row>
                <Col md={6} lg={3} className="fAboutUs">
                    <h5>ABOUT US</h5>
                    <span className="animate-border"></span>
                    <p className="aboutUsDes">With IssueGuard Services, your business can thrive in today’s digital landscape through our security-first approach to DevOps, Security. We safeguard your operations while optimizing performance</p>
                    <ul className="socialIcons">
                    <li>
    <a onClick={scrollUP} href="#" target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={faFacebook}/>
    </a>
</li>
<li>
    <a onClick={scrollUP} href="https://x.com/Tyagi_Talks" target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={faTwitter}/>
    </a>
</li>
<li>
    <a onClick={scrollUP} href="https://www.instagram.com/sarthak_tyagi_ji/" target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={faInstagram}/>
    </a>
</li>
<li>
    
    <a onClick={scrollUP} href="https://www.linkedin.com/in/tyagisarthak/" target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={faLinkedinIn}/>
    </a>
</li>

                        
                    </ul>
                </Col>
                <FooterCol key="2" menuItems={usefulLink} title="USEFUL LINK"/>
                <FooterCol key="3" menuItems={ourServices} title="OUR SERVICES"/>
                <FooterCol key="4" menuItems={otherLinks} title="OTHER LINKS"/>
            </Row>
            <p className="copyRight">Copyright &copy; 2021 <span className="fHighlight">Sarthak Tyagi</span>. All rights reserved.</p>
        </section>
    );
};

export default Footer;